@import "../../../base/fn";

.weui_label{
  display:block;
  width:@weuiCellLabelWidth;
}
.weui_input {
    width: 100%;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    height: unit(@weuiCellLineHeight, em);
    line-height: @weuiCellLineHeight;

    // hides the spin-button
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
}
.weui_textarea {
    display: block;
    border: 0;
    resize: none;
    width: 100%;
    color: inherit;
    font-size: 1em;
    line-height: inherit;
    outline: 0;
}

.weui_textarea_counter{
    color: @globalDescColor;
    text-align: right;
    .weui_cell_warn &{
        color: @globalWarnColor;
    }
}

.weui_toptips {
    display:none;
    position: fixed;
    -webkit-transform: translateZ(0);
    width: 100%;
    top: 0;
    line-height: 2.3;
    font-size:14px;
    text-align: center;
    color: #FFF;
    z-index: 2;
    &.weui_warn {
        background-color: @globalWarnColor;
    }
}
.weui_cells_form {
    .weui_cell_warn{
        color:@globalWarnColor;
        .weui_icon_warn{display:inline-block;}
    }
    .weui_cell_ft{font-size:0;}
    .weui_icon_warn{
        display:none;
    }
    input, textarea, label[for]{
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}
